import React, { useState } from 'react';
import { useApiGateway } from 'hooks/useApi';
import classNames from 'styles/utils/classNames';
import * as Sentry from '@sentry/nextjs';
import firebase from 'firebase';
import { auth } from 'services/client/firebase';
import Google from 'svg/Google';

const NEWSLETTER_KEY = 'AlertsNewsletter';

export default function NewsLetterForm() {
  const [email, setEmail] = useState('');
  const { post: newsletterSignupFetch, isLoading, isSuccess } = useApiGateway('/v1/email-list');
  const isDisabled = isLoading;

  const handleNewsletterSignup = async (emailToUse: string) => {
    try {
      await newsletterSignupFetch({
        payload: {
          email: emailToUse,
          listId: NEWSLETTER_KEY,
        },
      });
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  const handleGoogleSignup = async () => {
    if (isDisabled) return;

    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const result = await auth.signInWithPopup(provider);
      if (result.user?.email) {
        await handleNewsletterSignup(result.user.email);
        setEmail('');
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <>
      {isSuccess ? (
        <div className="text-pretty rounded-2xl bg-white p-4 text-center font-bold text-green-600">
          Success! You'll receive our free daily newsletter.
        </div>
      ) : (
        <div className="flex w-full flex-col gap-4">
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              if (isDisabled) return;
              try {
                await handleNewsletterSignup(email);
                setEmail('');
              } catch (error) {
                Sentry.captureException(error);
              }
            }}
            className="mx-auto flex w-full flex-col gap-4 md:flex-row"
          >
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className={classNames(
                'min-w-0 flex-auto rounded-md border-0 bg-white px-3.5 py-2 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6',
                isDisabled ? 'cursor-not-allowed opacity-50' : '',
              )}
              placeholder="Email..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isDisabled}
            />
            <button
              type="submit"
              className={classNames(
                'w-full flex-none rounded-md bg-brand-primary px-2.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white hover:text-brand-primary focus-visible:outline focus-visible:outline-2 md:w-48',
                isDisabled ? 'cursor-not-allowed opacity-50' : '',
              )}
              disabled={isDisabled}
            >
              {isDisabled ? 'Submitting...' : 'Submit'}
            </button>
          </form>

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-600"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="bg-palette-black-3 px-2 text-gray-500">Or subscribe with</span>
            </div>
          </div>

          <button
            type="button"
            onClick={handleGoogleSignup}
            disabled={isDisabled}
            className={classNames(
              'mx-auto flex w-full items-center justify-center space-x-2 rounded-md border border-gray-300 bg-white px-2.5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 md:w-64',
              isDisabled ? 'cursor-not-allowed opacity-50' : '',
            )}
          >
            <Google className="h-5 w-5" />
            <span>{isDisabled ? 'Subscribing...' : 'Subscribe with Google'}</span>
          </button>
        </div>
      )}
    </>
  );
}
